<template>
    <svg v-bind="$attrs" logo>
        <symbol id="logo-color" viewBox="0 0 512 114">
            <rect width="512" height="114" fill="none"/>
        </symbol>
        <use href="#logo-color" />
    </svg>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue';

export default {
    name: "AppLogo",
    components: {},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data = ref(null)
        const clr = ref('#e68346')

        return {
            data,
            clr
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
