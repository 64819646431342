import { ref, toValue, watchEffect } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { getQuery } from '@/javascript/lib/queries'
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'

/**
 * Wrap a collection of words marking specific candidates as "focus" words.
 *
 * @param {string} subject The subject to wrap words
 * @param {Array} keywords An array of keywords to wrap
 * @param {string} keyattr The primary attribute of the wrapping span element
 * @param {string} focusattr The secondary, focus, attribute the wrapping span element
 * @returns An HTML string with each word wrapped in a a span tag
 */
export const useWrappedString = (subject, keywords = [], keyattr = 'rs-key', focusattr = 'rs-focus') => {
    const kw   = [...keywords] ?? []
    const rex  = /((?=\S*['"-+])([0-9a-zA-Z&"'-+]+)|([&\-+])|(\w+))/mgi
    const mm   = subject.match(rex)
    return (mm?.length && kw.length) ? mm.map( w => {
            const m  = w.toLowerCase().replace(/([.,\/#!$%\^\*:{}=_`~()]|\s{1,})/g, '')
            const el = document.createElement('span')
            el.innerText = w
            el.setAttribute(keyattr, w?.toLowerCase())
            if (kw.includes(m)) {
                el.setAttribute(focusattr, '')
            }
            return el.outerHTML
        }).join(' ') : subject
}

/**
 * Pads a string by a number of characters.
 *
 * @param {string|number} v The subject string or integer to padd
 * @param {number} padding The length of the required padding
 * @param {string} padchr The padded character
 * @returns The padded string
 */
export const usePadIndex = (v, padding = 2, padchr = '0') => {
    return `${(v + 1).toString().padStart(padding, padchr)}`
}

/**
 * Format a string
 *
 * @param {string|number} v The subject string to format
 * @param {string} type The format type, accepts Number, Currecy or Text (default)
 * @returns
 */
export const useStringFromatter = (v, type) => {
    const val   = v
    const l     = 'en-CA'
    switch (type?.toLowerCase()) {
        case 'number':
            return new Intl.NumberFormat(l, { maximumSignificantDigits: 3, maximumFractionDigits: 0 }).format(val)
        case 'currency':
            return new Intl.NumberFormat(l, { style: 'currency', currency: 'CAD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(val)
        case 'text':
        default:
            return val
    }
}

/**
 * Sleep
 *
 * @param {number} delay The duration of the delay
 * @returns Returns a promisified delay
 */
export const sleep = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay))
}

/**
 * Fetch component data from an graphql endpoint
 *
 * @param {object} params An object literal containing id, componentid, component properties as params
 * @returns
 */
export const useComponentdata = (params = {}) => {
    const response  = params?.data
    const error     = ref(null)
    const store     = useStore();

    const fetchdata = () => {
        const id        = toValue(params.query)
        const api       = getAxiosInstance()
        const query     = getQuery(id)
        const payload   = (typeof query === 'function') ? query(params) : {}
        const endpoint  = '/graphql'

        if (payload?.constructor == Object && ('query' in payload ?? {})) {
            response.value = null
            error.value = null
            api.post(endpoint, payload, axioscacheconfig).then( r => {
                if ('readComponent' in (r?.data?.data ?? {})) {
                    response.value = r?.data?.data?.readComponent?.props
                }
             }).catch(err => {
                error.value = err
                console.log(error, error.response)
            })
        } else {
            throw "Invalid query. Unable to request component data."
        }
    }

    watchEffect(() => {
        fetchdata()
    })

    return {response, error}

}

// Component Property Composables
export const useNormalizeComponentProps = (source) => {
    const s = toValue(source)
    const p = {...s?.layout ?? {}}
    if (!!p.contentprops) {
        delete p.contentprops // @todo this should be handled in the graphql output
    }
    if (!!p.videobackground) {
        delete p.videobackground // @todo this should be handled in the graphql output
    }
    const props = ref(p)
    s?.ratios?.map( v => {
        props.value[v.scale] = v.value
    })
    return {props}
}

export const useNormalizeContentProps = (source) => {
    const s = toValue(source)
    const p = [...s?.layout?.contentprops ?? []]
    const properties = ref({})
    p?.map( v => {
        properties.value[v.key] = v.value
    })
    return {properties}
}

export const useNormalizeVideoBGProps = (source) => {
    const s = toValue(source)
    const p = {...s?.layout?.videobackground}
    const videoproperties = ref({})
    videoproperties.value = p
    return {videoproperties}
}

export const useNormalizeLayoutProps = (source) => {
    const s = toValue(source)
    const p = {...s?.layout}
    delete p.contentprops // @todo this should be handled in the graphql output
    const layout = ref(p)
    return {layout}
}

export const useNormalizeGridProps = (source) => {
    const s = toValue(source)
    const grid = ref({})
    const gap = ref({})
    s?.grid?.span?.map( v => { // @todo this should be handled in the graphql output
        grid.value[v] = ''
    })
    gap.value = s?.grid?.gap
    return {grid, gap}
}

export const useNormalizeRatioProps = (source) => {
    const s = toValue(source)
    const ratios = ref({})
    s?.ratios?.map( v => {
        ratios.value[v.scale] = v.value
    })
    return {ratios}
}

// NORMALIZE SLIDE CONFIGURATION
export const useNormalizeSlideValues = (config, count) => {
    const c = {...config}
    const l = count
    if (c.slidesToShow > l) {
        c.slidesToShow = c.slidesToScroll = l
    }
    return c
}
