// CSS
import '@/styles/main.scss'

//JS Application
import { createApp, defineCustomElement } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Appbar from '@/components/Appbar.ce.vue'
import AppFooter from '@/components/AppFooter.ce.vue'
import Address from '@/components/Address.ce.vue'
import UIElementalPageHeader from '@/components/UIElementalPageHeader.ce.vue'
import UIElementalMosaic from '@/components/UIElementalMosaic.ce.vue'
import UIElementalTriptych from '@/components/UIElementalTriptych.ce.vue'
import UIElementalCarousel from '@/components/UIElementalCarousel.ce.vue'
import UIElementalContent from '@/components/UIElementalContent.ce.vue'
import UIElementalGallery from '@/components/UIElementalGallery.ce.vue'
import UIElementalImage from '@/components/UIElementalImage.ce.vue'
import UIElementalHeading from '@/components/UIElementalHeading.ce.vue'
import UIElementalCallout from '@/components/UIElementalCallout.ce.vue'
import UIElementalStoriesList from '@/components/UIElementalStoriesList.ce.vue'
import UIElementalRepeater from '@/components/UIElementalRepeater.ce.vue'
import UIElementalBanner from '@/components/UIElementalBanner.ce.vue'

import Umbra from './javascript/modules/umbra'

// Umbra singleton
const U = ('__umbrastate__' in window) ? new Umbra({state:{
    active:true,
    ...window['__umbrastate__']
}}) : new Umbra({state:{
    active:true
}})

/**
 * @link https://vuejs.org/guide/extras/web-components.html#building-custom-elements-with-vue
 */
export function register() {
    customElements.define('rs-appbar',                      defineCustomElement(Appbar))
    customElements.define('rs-appfooter',                   defineCustomElement(AppFooter))
    customElements.define('rs-address',                     defineCustomElement(Address))
    customElements.define('rs-uielementalheader',           defineCustomElement(UIElementalPageHeader))
    customElements.define('rs-uielementalmosaic',           defineCustomElement(UIElementalMosaic))
    customElements.define('rs-uielementaltriptych',         defineCustomElement(UIElementalTriptych))
    customElements.define('rs-uielementalcarousel',         defineCustomElement(UIElementalCarousel))
    customElements.define('rs-uielementalcontent',          defineCustomElement(UIElementalContent))
    customElements.define('rs-uielementalgallery',          defineCustomElement(UIElementalGallery))
    customElements.define('rs-uielementalimage',            defineCustomElement(UIElementalImage))
    customElements.define('rs-uielementalheading',          defineCustomElement(UIElementalHeading))
    customElements.define('rs-uielementalcallout',          defineCustomElement(UIElementalCallout))
    customElements.define('rs-uielementalstorieslist',      defineCustomElement(UIElementalStoriesList))
    customElements.define('rs-uielementalrepeater',         defineCustomElement(UIElementalRepeater))
    customElements.define('rs-uielementalbanner',           defineCustomElement(UIElementalBanner))
}

const app = createApp(App)
app.use(createPinia())
app.mount('#App')

register();
