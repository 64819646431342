<template>
    <Suspense>
        <template #default>
            <header ref="root" v-if="data?.image?.id" rs-elemental="uielementalpageheader" :="layoutprops" stackable :svganimate="svganimate">
                <rs-media v-if="image?.url && data?.image?.sources">
                    <template v-if="rendervideobg">
                        <iframe ref="videoplayer" v-if="videoprops.type == 'vimeo'" :id="`vimeo-${videoprops.id}`" :data-delay="videoprops.delay" :src="`//player.vimeo.com/video/${videoprops.id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&controls=0&background=1&autoplay=1`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="videobg videobg--root" :title="data?.title" :type="videoprops.type" loading="lazy"></iframe>
                        <iframe rev="videoplayer" v-else :id="`ytplayer--${videoprops.id}`" class="videobg videobg--root" :data-videoid="videoprops.id" frameborder width="100%" height="720" :title="data?.title" allow="playsinline autoplay allowfullscreen muted" :src="`https://www.youtube.com/embed/${videoprops.id}?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&vq=hd1080&playlist=`" :type="videoprops.type" loading="lazy"></iframe>
                    </template>
                    <Picture :config="data?.image" :alt="data?.image?.alt ?? data?.title" />
                </rs-media>

                <rs-container v-if="showtitle || showcontent || showlinks">
                    <rs-heading v-if="showtitle">
                        <h1 ref="heading" heading :focustext="focustext" scale="xl" weight="regular" casing="display-mm" gutters="0" caps v-html="wrappedtitle"></h1>
                    </rs-heading>

                    <rs-content v-if="showcontent" v-html="data?.content"></rs-content>

                    <rs-actions v-if="showlinks">
                        <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" :class="item?.css" chunky />
                    </rs-actions>
                </rs-container>
            </header>
            <MDSkeleton v-else aspect-16-9 error="true" spin />
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useWrappedString, useComponentdata, useNormalizeComponentProps, useNormalizeVideoBGProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import Player from "@vimeo/player"

export default {
    name: "UIElementalPageHeader",
    components: {Picture, MDButton, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const videoplayer   = ref(null)
        const heading       = ref(null)
        const __to          = ref(null)
        const videoprovider = ref(null)
        const svganimate    = ref(null)
        const data          = ref(null)
        const image         = ref(null)
        const layoutprops   = ref({})
        const videoprops    = ref({})
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const showcontent   = computed(() => data?.display?.displaycontent && data?.content)
        const showlinks     = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle     = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const rendervideobg = computed(() => !!videoprops?.value && !!videoprops?.value?.url)
        const focustext     = computed(() => ([...data.value?.focustext] ?? [])?.length ? true : null)

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(root.value, () => {
                    __to.value = setTimeout(() => {
                        heading.value?.setAttribute('easing', '')
                        clearTimeout(__to.value)
                    }, 2500);
                })
            }
        })

        watch(videoplayer, (n, o) => {
            if(!(videoplayer.value instanceof HTMLElement && rendervideobg.value)) {
                return false
            }
            const instance = videoplayer.value
            const p = new Player(instance)
            const settings   = {
                threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]//[0, 0.25, 0.5, 0.75, 1]
            }
            // @todo Replace this intersection objserver with composable useIntersectionObserver
            // @link https://vueuse.org/core/useIntersectionObserver/
            const observer = new IntersectionObserver(entries => {
                entries.map((entry) => {
                    const details = {
                        id:             entry.target.getAttribute('id'),
                        type:           entry.target.getAttribute('type').toLowerCase(),
                        element:        entry,
                        intersecting:   entry.isIntersecting,
                        ratio:          entry.intersectionRatio
                    }
                    if(details.ratio >= 0.2) {
                        p.play().then(() => {
                            if(['localhost', 'dev'].includes(store.mode)) {
                                // console.log(`The video (${details.id}) is playing...`)
                            }
                            svganimate.value = 1
                        }).catch((error) => {
                            console.log(error)
                            switch (error.name) {
                                case 'PasswordError':
                                    break // The video is password-protected
                                case 'PrivacyError':
                                    break // The video is private
                                default:
                                    break // Some other error occurred
                            }
                        })
                    }else{
                        p.pause().then(() => {
                            if(['localhost', 'dev'].includes(store.mode)) {
                                // console.log(`The video (${details.id}) is paused...`)
                            }
                            svganimate.value = 0
                        }).catch((error) => {
                            console.log(error)
                            switch (error.name) {
                                case 'PasswordError':
                                    break // The video is password-protected
                                case 'PrivacyError':
                                    break // The video is private
                                default:
                                    break // Some other error occurred
                            }
                        })
                    }
                })
            }, settings)
            observer.observe(instance)
        })

        watch(data, (n, o) => {
            const {props}           = useNormalizeComponentProps(data)
            const {properties}      = useNormalizeContentProps(data)
            const {videoproperties} = useNormalizeVideoBGProps(data)

            layoutprops.value       = {...props.value, ...properties.value ?? {}}
            videoprops.value        = {...videoproperties.value}
            image.value             = data.value?.image
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            videoplayer,
            heading,
            data,
            showcontent,
            showlinks,
            showtitle,
            focustext,
            wrappedtitle,
            layoutprops,
            videoprops,
            rendervideobg,
            image,
            svganimate
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
