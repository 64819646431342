<template>
    <rs-container ref="root" rs-elemental="uielementalgallery" :="layoutprops">
        <Suspense>
            <template #default>
                <rs-media stackable>
                    <rs-grid ref="gallery" v-if="collection?.size" assets gap-0  xxs-2 sm-12 :="contentprops" stackable-item>
                        <GalleryTile v-for="(item, index) in collection.values()" :config="item" />
                    </rs-grid>
                </rs-media>
            </template>
            <template #fallback>
                <MDSkeleton aspect-1-1>
                    <MDBlocker active="true" cursor="default" type="default" />
                </MDSkeleton>
            </template>
        </Suspense>
    </rs-container>
</template>

<script>
import { ref, watch, computed, onBeforeMount, createApp } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useComponentdata, useNormalizeLayoutProps, useNormalizeRatioProps, useNormalizeGridProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import Picture from './Picture.ce.vue'
import MDDialog from './MDDialog.ce.vue'
import GalleryTile from './GalleryTile.vue'
import MDSkeleton from './MDSkeleton.ce.vue'

export default {
    name: "UIElementalGallery",
    components: {GalleryTile, Picture, MDSkeleton, MDDialog},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store             = useStore()
        const root              = ref(null)
        const gallery           = ref(null)
        const asset             = ref(null)
        const data              = ref(null)
        const config            = ref(null)
        const assets            = ref(null)
        const collection        = ref(null)
        const layoutprops       = ref(null)
        const contentprops      = ref(null)
        const gridprops         = ref(null)
        const dialog            = ref(null)
        const dom               = ref(null)

        const getDialog = (asset) => {
            if (!!asset) {
                const template  = document.createElement('template')
                const assettitle = asset?.alt ?? asset?.title ?? 'DRaspberry Swwwirl'
                const img = `<picture asset-id="${asset?.id}" title="${assettitle}">
                    <source srcset="${getSrcSet(asset)}" type="${asset?.mimetype}">
                    <img ref="asset" src="${asset?.url ?? asset?.thumbnail}" alt="${data?.alt}" loading="lazy" easing>
                </picture>`
                let instance    = createApp(MDDialog, {config: asset}).mount(document.createElement('template'))
                instance.$el.querySelector('dialogcontent').innerHTML = img
                template.innerHTML = instance.$el.cloneNode(true).outerHTML
                instance = null
                return template.content.querySelector('dialog')
            }
            return null
        }

        const getSrcSet = (asset) => {
            const srcs = []
            const bps = []
            if (!!asset?.sources?.length) {
                asset?.sources.map( v => {
                    if (!bps.includes(v.breakpointatt)) {
                        const s = `${store.baseurl}${v.image}`
                        srcs.push(`${s} ${v.breakpointatt}w`)}
                        bps.push(v.breakpointatt)
                    }
                )
            }

            return srcs.join(', ')
        }

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(asset, (n, o) => {
            if (!!n) {
                dom.value = (dom.value instanceof HTMLElement) ? dom.value : document.querySelector('body')

                if (dom.value) {
                    dialog.value?.remove() // Try to remove any dialogs if the state was lost
                    dialog.value = getDialog(asset.value)
                    dom.value?.prepend(dialog.value)

                    if (dialog.value instanceof HTMLElement) {
                        dialog.value?.addEventListener('click', (e) => {
                            console.log(e.target.classList)

                            if (e.target.classList.contains('dialog-close')) {
                                e.stopImmediatePropagation()
                                dialog.value?.close()
                            }
                        })

                        dialog.value?.addEventListener('close', (e) => {
                            console.log('close remove...')
                            dialog.value?.remove()
                            reset()
                        })

                        dialog.value?.showModal()
                    }
                }
            }
        })

        const reset = () => {
            dialog.value = null
            asset.value = null
        }

        watch(gallery, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                gallery.value?.addEventListener('click', (e) => {
                    const el = e.target
                    const uuid = parseInt(el.getAttribute('asset-id') ?? 0, 10)
                    asset.value = collection.value?.get(uuid) ?? null
                })
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout} = useNormalizeLayoutProps(data)
                const {ratios} = useNormalizeRatioProps(data)
                const {grid, gap} = useNormalizeGridProps(data)
                const {properties} = useNormalizeContentProps(data)
                layoutprops.value = {...layout.value, ...ratios.value, ...grid.value}
                gridprops.value = {...grid.value}
                if (!!gap.value) {
                    gridprops.value[gap.value] = ''
                }

                assets.value = [...newvalue?.assets ?? []]
                collection.value = new Map()
                newvalue?.assets?.map( v => {
                    if (v.id) {
                        collection.value.set(parseInt(v.id ?? 0, 10), v)
                    }
                })

                config.value = {...newvalue?.glider ?? {}}
                contentprops.value = {...properties.value ?? {}}
                // Splice in the template value as an attribute
                if (layoutprops.value?.template) {
                    contentprops.value[layoutprops.value?.template] = ''
                }

                // Splice in the 'interactive' property as an attribute
                if ('interactive' in contentprops.value) {
                    if (!!contentprops.value?.interactive) {
                        contentprops.value['interactive'] = ''
                    }
                }

                // Splice in the 'standalone' property as an attribute
                if ('standalone' in contentprops.value) {
                    layoutprops.value['standalone'] = ''
                }
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readGalleryCollection', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            assets,
            config,
            layoutprops,
            contentprops,
            collection,
            gallery,
            asset
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

