<template>
    <template v-if="data.id">
        <dialog v-bind="$attrs" :="dialogprops" :id="`dialog-${data?.id}`" :aria-labelledby="`dialog-${data?.id}_title`" :aria-describedby="`dialog-${data?.id}_description`" class="dialog dialog--root">
            <dialogheading :id="`dialog-${data?.id}_title`" class="dialog-heading" v-html="`${data.title ?? 'Raspberry Swwwirl'}`"></dialogheading>
            <dialogcontent :id="`dialog-${data?.id}_description`" class="dialog-content"></dialogcontent>
            <button class="dialog-close" noappearance><icon class="md-icon">close</icon></button>
        </dialog>
    </template>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue';
import Picture from './Picture.ce.vue'

export default {
    name: "MDDialog",
    components: {Picture},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data  = ref(null)
        const type = ref('generic')
        const dialogprops = ref(null)

        onBeforeMount(() => {
            data.value = props.config
            type.value = data.value?.type ?? 'gallery'
            dialogprops.value = type.value == 'gallery' ? {presentation: '', gallery: ''} : {modal: ''}
        })

        return {
            data,
            dialogprops
        }
    }
}
</script>
