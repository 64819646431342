<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalstorieslist" :="layoutprops">
                <template v-if="loading">
                    <MDSkeleton aspect-16-9 error="true" spin />
                </template>
                <template v-else>
                    <rs-grid xxs-1 sm-12>
                        <template v-if="!!storieslist?.length">
                            <StoryCard v-for="(item, index) in storieslist" :id="`event-${item?.props?.id}`" :key="index" v-memo="storieslist" :config="item"></StoryCard>
                        </template>
                    </rs-grid>
                </template>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useStore } from '@/javascript/lib/store'
import { useWrappedString , useComponentdata, useNormalizeComponentProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDText from '@/components/MDText.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import StoryCard from '@/components/StoryCard.vue'
import TrieSearch from 'trie-search'

export default {
    name: "UIElementalStoriesList",
    components: {MDButton, MDSkeleton, MDBlocker, MDText, StoryCard},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root              = ref(null)
        const store             = useStore()
        const data              = ref(null)
        const filterform        = ref(null)
        const resetbutton       = ref(null)
        const layoutprops       = ref(null)
        const contentprops      = ref(null)
        const stories           = ref([])
        const storieslist       = ref([])
        const rendergroups      = ref(false)
        const eventgroups       = ref(null)
        const term              = ref(null)
        const loading           = ref(true)
        const regions           = ref(null)
        const categories        = ref(null)
        const fitlerfields      = ref(null)
        const datefilters       = ref(null)
        const selecteddates     = ref([])
        const selected          = (value) => {
            return !![...selecteddates.value?.values() ?? []]?.includes(value) ? {selected: ''} : {selected: null}
        }
        const formsubmit        = (e) => {
            e.preventDefault()
        }
        const dataset           = ref(null)
        const label             = ref(null)
        const links             = ref(null)
        const delay             = ref(320)
        const showfilters       = ref(false)
        const showcontent       = computed(() => data.value?.display?.displaycontent)
        const showtitle         = computed(() => data.value?.display?.showtitle)
        const showlinks         = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle      = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const _trieOptions          = ref({
            fields: ['terms', 'regions'],
            config: {
                min: 3,
                idFieldOrFunction: 'id',
                ignoreCase: true
            }
        })

        /**
         * Get a TrieSearch instance
         *
         * @param {array} dataset A collection of marker data used for searching
         * @link https://github.com/joshjung/trie-search?tab=readme-ov-file#deep-array-mapping
         */
         const getTrieIntance = (dataset) => {
            const t = new TrieSearch(_trieOptions.value.fields, _trieOptions.value.config)
            if(Array.isArray(dataset)) {
                t.addAll(dataset)
                // The categories & regions are arrays, so deep mapping of these fields is required.
                dataset.map( v => {
                    v?.regions.map( r => {
                        t.map(r, v)
                    })
                    v?.terms.map( c => {
                        t.map(c, v)
                    })
                })
            }
            return t
        }

        /**
         * Watch changes to the term. If this ref changes, react by performing a search.
         * The search uses a "Trie Tree" algorithm to quickly search through a collection of
         * our object instances.
         */
        watch(term, (n, o) => {
            if (!!n) {
                // filter null values
                storieslist.value = null
                let results = []

                /**
                 * Iterate over the term keys and filter the dataset one field at a time.
                 *
                 * The logical progression of the search doesn't have to start with `regions`, instead
                 * this method will reduce the terms beginning with the either field (single value)
                 * to reduce the number of results spread from our dataset ref.
                 */
                const fields = Object.keys(term.value)
                if(!!fields.length) {
                    let filteredset = [...dataset.value]
                    fields.map( f => {
                        const t = getTrieIntance(filteredset)
                        const terms = !!term.value[f] ? term.value[f] : ['all']
                        filteredset = t.search(terms)
                    })

                    results = [...filteredset]
                }

                // Sort alphabetically, extract record (object) instance
                results = results.sort((a,b) => a.record.props.title.localeCompare(b.record.props.title))
                results = [...results ?? []].map(item => item.record)

                storieslist.value   = results
                label.value         = `${storieslist.value?.length} Matches`
                loading.value       = false
            }
        })

        const inputreset = (e) => {
            console.log('reset', filterform.value)
            if (filterform.value instanceof HTMLFormElement) {
                filterform.value.reset()
                selecteddates.value = []
                selected()
                storieslist.value = stories.value
            }
        }

        const filterhandler = (e) => {
            // loading.value = true
            const frm   = e.target?.closest('form')

            if (frm instanceof HTMLFormElement) {
                const data = [...(new FormData(frm)).values()]?.map( v => parseInt(v, 10))
                selecteddates.value = data
                if (selecteddates.value?.length) {
                    storieslist.value = stories.value?.filter( p => {
                        const {properties}  = useNormalizeContentProps(p?.props ?? {})
                        const d = new Date(properties.value?.startdatetime)
                        const y = d?.getFullYear()
                        const m = d?.getMonth()
                        return !!(selecteddates.value?.includes(m))
                    } )
                }
            }
        }

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                const { properties } = useNormalizeContentProps(data)
                contentprops.value   = { ...properties.value ?? {} }

                links.value = [...data.value?.links ?? []]

                // Assign components to stories ref
                storieslist.value = stories.value = data.value.components

                // Intialize our dataset ref
                dataset.value = []

                // Group stories by Year Month
                if (!!rendergroups.value) {
                    eventgroups.value = new Map()
                    storieslist.value?.map( v => {
                        const {properties}  = useNormalizeContentProps(v?.props ?? {})
                        const d = new Date(properties.value?.startdatetime)
                        const y = d?.getFullYear()
                        const m = d?.getMonth()
                        if (!!y && !!m) {
                            if (!eventgroups.value?.has(y)) {
                                eventgroups.value?.set(y, new Map())
                                eventgroups.value?.get(y)?.set(m, [])
                            }
                            eventgroups.value?.get(y)?.get(m)?.push(v)
                        }
                    } )
                }

                const months = [...Array(12).keys()]
                datefilters.value = new Map()
                months.map( v => {
                    const m = v
                    const d = new Date()
                    d.setMonth(m)
                    datefilters.value?.set(m, d)
                })

                // Intialize our filterfields and segment them by types
                // fitlerfields.value = new Map()
                // fitlerfields.value.set('regions', [])
                // fitlerfields.value.set('terms', [])
                // data.value.components?.map( v => {
                //     // Build a map of regions and terms
                //     v?.props.taxonomies?.map( j => {
                //         const key = j.typeid
                //         const val = j.name
                //         switch(key) {
                //             case 7: // regions
                //                 fitlerfields.value.set('regions', [...(new Set([...fitlerfields.value.get('regions'), val]))])
                //                 break;
                //             default: // everything else is a term
                //                 fitlerfields.value.set('terms', [...(new Set([...fitlerfields.value.get('terms'), val]))])
                //                 break;
                //         }
                //     })

                //     // Generate a Trie Tree
                //     const trie = {
                //         id:         v.props?.id,
                //         record:     v,
                //         terms:      [...v?.props.taxonomies?.map(tr => (tr.typeid !== 7) ? tr.name : null).filter(tr => !!tr), 'All'],
                //         regions:    [...v?.props.taxonomies?.map(tr => (tr.typeid === 7) ? tr.name : null).filter(tr => !!tr), 'All'],
                //     }

                //     dataset.value.push(trie)
                // }) ?? [];

                // // Build our filter field values
                // categories.value    = [...(new Set([...fitlerfields.value.get('terms')]))].sort((a,b) => a.localeCompare(b))
                // regions.value       = [...(new Set([...fitlerfields.value.get('regions')]))].sort((a,b) => a.localeCompare(b))

                // Initialize the first search by passing an object literal with the default search terms
                // term.value = {regions: 'All', terms: 'All'} // !!! ENABLE THIS
                loading.value = false
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readListingContent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            label,
            regions,
            categories,
            layoutprops,
            contentprops,
            stories,
            storieslist,
            links,
            showfilters,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            filterhandler,
            loading,
            datefilters,
            selecteddates,
            selected,
            inputreset,
            filterform,
            formsubmit
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
